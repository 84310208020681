@import '../../variables.scss';

.modal_background {
    overflow: visible;
    width: 100%;
    min-height: 100vh;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;

    &.big {
        position: absolute;
        align-items: flex-start;
        overflow: visible;
        min-height: 100%;
    }

    background: rgba($modal-back-bg-color, $modal-back-bg-opacity);
    backdrop-filter: blur(20px);

    & .modal {
        max-width: 1280px;
        height: fit-content;
        padding: 40px;
        margin: 70px 80px 80px;
        background: $bg-color;
        border-radius: 20px;
        position: relative;
        overflow: hidden;
        box-shadow: 0px 0px 1px rgba(26, 32, 36, 0.16), 0px 8px 24px rgba(91, 104, 113, 0.14);

        &__btns {
            position: absolute;
            top: 40px;
            right: 37px;
            z-index: 5;

            @media screen and (max-width: $mobile) {
                top: 20px;
                right: 20px;
            }
        }

        &__close {
            padding: 0;
            width: 60px;
            height: 60px;

            & svg {
                margin: 0;
            }

            &.unfilled {
                width: auto;
                height: auto;
                padding: 0;
                background: 0;
                border: 0;

                & svg {
                    width: 30px;
                    height: 30px;
                }
            }

            &.with_confirm {
                top: 0;
                position: absolute;
                right: 72px;
            }

            @media screen and (max-width: $mobile) {
                width: 45px;
                height: 45px;


                & svg {
                    width: 13px;
                    height: 13px;
                }

                &.with_confirm {
                    right: 50px;
                }

                &.unfilled {
                    & svg {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }

        &.big {
            width: 100%;
        }

        &.not_full_width {
            width: fit-content;
        }

        @media screen and (max-width: $tablet) {
            margin: 20px;
        }

        @media screen and (max-width: $menu) {
            padding: 20px;
            width: 100%;
        }
    }
}