@import '../../variables.scss';

.page_header {
    & .title {
        text-align: left;
    }

    & .grey {
        color: rgb(158, 169, 169);
        margin-left: 20px;
    }

    & .arr_container {
        width: 60px;
        height: 60px;
        border-radius: $br-12;
        border: 1px solid $light-gray-more-opacity;
        margin-right: 20px;
    }

    &__arr {
        display: block;
        width: 26px;
        height: 19px;
    }

    &__link {
        width: fit-content;
    }

    @media screen and (max-width: $mobile) {
        & .arr_container {
            width: 40px;
            height: 40px;
            margin-right: 15px;
        }

        &__arr {
            width: 18px;
            height: 13px;
        }
    }
}