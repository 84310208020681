@import "../../variables";

.typed_select {
    flex-grow: 1;
    position: relative;
    
    & .select__arr {
        top: 25px;
        right: 15px;
        position: absolute;
        transition: all 0.5s ease;

        &.open {
            transform: rotate(180deg);
        }
    }

    & .input_container {
        position: relative;
    
        & .select__arr {
            top: 25px;
            right: 15px;
            position: absolute;
            transition: all 0.5s ease;
    
            &.open {
                transform: rotate(180deg);
            }
        }
    }

    & .select_list_container {
        padding: 15px 15px 30px;
        opacity: 0;
        min-width: 16px;
        min-height: 16px;

        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        z-index: -1;

        background: $open-select-bg-color;
        box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
        border-radius: $input-radius !important;
        
        transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.3s;
        outline: 0;

        &.open {
            z-index: 5;
            opacity: 1;
            transform: none;
            min-width: 200px;
            transition: opacity 296ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 197ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }
        
        & ul {
            max-height: 270px !important;
            overflow: hidden;
            overflow-y: auto;
            scroll-behavior: smooth;
    
            scrollbar-color: rgba(16, 17, 18, 0.2) $open-select-bg-color;     /* «цвет ползунка» «цвет полосы скроллбара» */
            scrollbar-width: auto;  /* толщина */
    
            &::-webkit-scrollbar {
                width: 10px;
                background-color: $open-select-bg-color;
                top: 130px;
            }
    
            &::-webkit-scrollbar-thumb {
                width: 3px;
                background-color: rgba(16, 17, 18, 0.2);
                border-radius: 3px;
            }
    
            &::-webkit-scrollbar-thumb:hover {
                background-color: rgba(16, 17, 18, 0.2);
                width: 3px;
            }
    
        }
    }

    & .select_item {
        cursor: pointer;
        padding: 8px 10px;

        &:hover {
            background: rgba($selected-bg-color, 0.4);
        }

        &.selected {
            background: $selected-bg-color;
            border-radius: 20px;
        }

        &--with_btn {
            padding: 0;
        }
    }

    & .select_btn  {
        padding: 8px 10px !important;
        cursor: pointer;
        font: inherit;
        color: $text-color-light !important;
    }
}