.profile__span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.017em;
    text-align: left;
    color: rgba(16, 17, 18, 0.5);
    margin: 16px 0 4px 0;
}

.profile__subtitle {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.017em;
    text-align: left;
    color: rgba(16, 17, 18, 0.5);
    padding: 0 0 36px 0;
}

.btn__profile {
    display: flex;
    margin-bottom: 10px;
}

.address__start {
    color: rgba(16, 17, 18, 0.2) !important;
}

.address__part {
    padding-right: 10px;
    font-family: PT Mono;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: -0.022em;
    text-align: left;
    color: rgba(88, 89, 89, 1);
}

.address__address{
    margin-bottom: 16px;
}

.btn__exit {
    margin-bottom: 36px;
}

.profile__input {
    width: 768px;
    height: 60px;
    padding: 4px 12px 4px 12px;
    border-radius: 12px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.017em;
    text-align: left;
    background-color: rgba(16, 17, 18, 0.05);
    border: none;
    color: rgba(88, 89, 89, 1);
}