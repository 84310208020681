.button {
    padding: 4px, 8px, 4px, 8px;
    border-radius: 7px;
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center; 
    font-family: Inter;
    text-align: left;
}

.button:nth-child(even) {
    margin-left: 4px;
}

.button-active {
    background-color: rgba(231, 231, 231, 1);
}

.button-footer {
    width: 113px;
    height: 32px;
    gap: 6px;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.006em;
}

.button-adress {
    width: 101px;
    height: 26px;
    gap: 4px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.017em;
}