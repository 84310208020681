@import '../../../variables.scss';

.test_modal {
    margin-top: 20px;
    max-width: 780px;
    width: 100%;

    & .btn {
        margin-bottom: 10px;
    }

    &__text {
        margin-top: 36px;
        margin-bottom: 16px;
        font-size: 1.25rem; //20px
    }

    &__footer {
        margin-top: 24px;
    }

    &__icon {
        margin-right: 8px;

        &--success {
            width: 31px;
            height: 30px;
        }

        &--error {
            width: 35px;
            height: 33px;
        }
    }

    @media screen and (max-width: $mobile) {
        & .title {
            font-size: 2rem;
        }

        &__text {
            margin-top: 20px;
            margin-bottom: 10px;
            font-size: 1.1rem;
        }

        &__result {
            font-size: 1.1rem;
        }

        &__icon {
            &--success {
                width: 21px;
                height: 20px;
            }

            &--error {
                width: 25px;
                height: 23px;
            }
        }
    }
}