@import '../../../variables';

.rools__form {
    & .top_submit_btn {
        width: 60px;
        height: 60px;
        padding: 0;
        position: absolute;
        top: 40px;
        right: 36px;

        & svg {
            margin: 0;
        }

        @media screen and (max-width: $mobile) {
            width: 45px;
            height: 45px;
            top: 20px;
            right: 20px;

            & svg {
                width: 17px;
                height: 13px;
            }
        }
    }

    & .form__top_mess {
        position: absolute;
        top: 20px;
        left: 40px;

        @media screen and (max-width: $mobile) {
            max-width: 55%;
            top: -10px;
            left: 20px;
        }
    }

    & .form_part {
        max-width: 805px;
        padding: 40px 0;
        border-bottom: 1px solid $line-color;

        @media screen and (max-width: $mobile) {
            padding: 20px 0;
        }
    }

    & .MuiAutocomplete-clearIndicator {
        margin-right: 20px;
    }

    & .transaction__title_container {
        margin-bottom: 15px;

        @media screen and (max-width: 345px) {
            flex-direction: column;
        }

        & .subtitle {
            margin-bottom: 0;

            @media screen and (max-width: 345px) {
                margin-bottom: 15px;
            }
        }
    }

    & .button_container {
        margin: 20px 0 12px 0;
        width: 197px * 2;
        height: 60px;
        display: flex;
        justify-content: space-evenly;
        background-color: $select-bg-color;
        border-radius: 12px !important;

        & button {
            width: 185px;
            height: 48px;
            border-radius: 12px !important;
            border: none;
            font-family: $font-family;
            font-weight: 600;
            font-size: 16px;
            cursor: pointer;
        }
    }

    &__section {
        margin-top: 35px;

        @media screen and (max-width: $mobile) {
            margin-top: 35px;
        }
    }

    & .checkbox_container {
        display: inline-flex;
        align-items: center;
    }

    & .MuiFormControl-root {
        margin-bottom: 12px !important;
    }

    & .small-select {
        margin-right: 12px;
        max-width: 200px;
        width: 100%;

        & .MuiInputBase-root {
            max-width: 200px;
        }

        &.blue {
            & .MuiInputBase-root {
                color: $blue;
            }
        }

        &--strict-width {
            width: 200px;
        }

        @media screen and (max-width: $mobile) {
            max-width: 100%;

            & .MuiInputBase-root {
                max-width: 100% !important;
            }
        }
    }

    & .big-select {
        flex-grow: 1;
    }

    &__btn {
        margin-left: 8px;
        padding: 0;
        width: 56px;
        height: 56px;

        background: $select-bg-color;

        &__delete {
            margin-right: 0 !important;
            width: 21px;
            height: 27px;
        }
    }

    &__plus {
        width: 17px;
        height: 17px;
        margin-right: 0 !important;
    }

    & .notif_text {
        margin-right: 8px;
        line-height: 1.0;
    }

    & .notification {
        &__text {
            line-height: 1.4;
            margin: 5px 0 0 0;
        }

        & .bind_account_btn {
            margin-bottom: 20px;
        }

        &__example {
            margin: 20px 0 0 0;

            & .text {
                margin-bottom: 12px;
                font-size: 0.75rem; //12px
            }
        }

        & .example_block {
            min-height: 122px;
        }
    }

    & .test_mess_btn {
        max-width: 330px;
        width: 100%;
    }

    & .form_btns {
        margin-top: 80px;
    }

    @media screen and (max-width: $mobile) {
        & .message {
            margin-top: 30px;
        }

        & .title_input {
            margin-top: 40px;
        }

        & .subtitle {
            font-size: 1.1rem;
        }

        & .mobile_column {
            flex-direction: column;
        }

        & .form_btns {
            margin-top: 50px;
        }
    }
}