@import '../../variables.scss';

.MuiFormControl-root {
    margin: 0 !important;
    min-width: 100% !important;
}

.MuiFilledInput-input {
    font-size: 0.8rem !important; //13px
    color: $text-color-with-opacity !important;

    @media screen and (max-width: $mobile) {
        padding-right: 32px !important;
    }
}

.MuiInputBase-root {
    overflow: hidden;
    border-radius: $input-radius !important;
    background: $select-bg-color !important;
    min-height: 55px;

    &.Mui-error {
        border: 1px solid $bright-red;
    }

    &::before {
        display: none;
    }

    &::after {
        display: none;
    }

    @media screen and (max-width: $mobile) {
        min-height: 40px;
    }
}

.MuiSelect-filled {
    border-radius: $input-radius !important;
}

.MuiSelect-select {
    position: relative;
    border-radius: $input-radius !important;
    background: $select-bg-color !important;
}

.MuiSelect-icon {
    display: none !important;
}

.select__arr {
    display: block;
    width: 10px;
    height: 6px;

    position: absolute;
    top: 25px;
    right: 15px;
    transition: all 0.5s;
    z-index: 1;

    &.open {
        transform: rotate(180deg);
    }
}

.MuiFormHelperText-root {
    margin-left: 0 !important;
}

.select_input {
    &.open {
        & .MuiSelect-select {
            background: $open-select-bg-color;
            box-shadow: 0px 0px 1px rgba(26, 32, 36, 0.16), 0px 4px 12px rgba(91, 104, 113, 0.12);
        }
    }
}

.MuiPaper-root {
    margin-top: 5px;

    color: $bold-text-color;
    font-weight: 500;

    border-radius: $input-radius !important;
}

.MuiList-root {
    padding: 15px !important;
    overflow: hidden;
    overflow-y: scroll;
    max-height: 270px !important;
    scroll-behavior: smooth;

    scrollbar-color: rgba(16, 17, 18, 0.2) $open-select-bg-color;
    /* «цвет ползунка» «цвет полосы скроллбара» */
    scrollbar-width: auto;
    /* толщина */


    &::-webkit-scrollbar {
        width: 10px;
        background-color: $open-select-bg-color;
        top: 130px;
    }

    &::-webkit-scrollbar-thumb {
        width: 3px;
        background-color: rgba(16, 17, 18, 0.2);
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: rgba(16, 17, 18, 0.2);
        width: 3px;
    }
}

.Mui-selected {
    background: $selected-bg-color !important;
    border-radius: 20px !important;
}