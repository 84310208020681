// @import "./fonts";
@import "./variables";

html {
  min-height: 100%;
  font-family: $font-family;
  font-weight: 400;
  font-size: 16px;
  color: $text-color;

  box-sizing: border-box;
  scroll-behavior: smooth;
  background-color: $bg-color;

  // @media screen and (max-width: $mobile) {
  //   font-size: 14px;
  // }
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  position: relative;
}

a {
  font-size: 1rem;
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.5;
  }
}

.img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}

input,
select,
a,
ul,
li {
  font: inherit;
}

p {
  padding: 0;
  margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input:invalid {
  border-color: red;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

.list_style_type_none {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.visually-hidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  animation-name: hide;
  animation-duration: 3s;
  overflow: hidden;
}

button {
  color: $text-color;
}

button:focus {
  outline: none;
}

.btn {
  width: fit-content;
  // display: block;
  font-family: $font-family;
  color: inherit;
  cursor: pointer;
  border: 0;
  background: 0;
  border-radius: $btn-radius;
  font-size: 1.125rem; //18px
  font-weight: 600;
  padding: 0.625rem 1.25rem; //15px 20px
  background: $btn-bg;

  & svg {
    margin-right: 5px;
  }

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.5;
  }

  &.log_in_btn {
    padding: 15px 24px;

    font-size: 1.375rem; //22px
    color: $light;
    letter-spacing: -0.374px;
    font-weight: bold;

    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);

    @media screen and (max-width: $tablet) {
      font-size: 1.125rem; //18px      
    }
  }

  &--thin {
    font-size: 1.125rem; //18px
    padding: 0.4em 0.6em; //8px 12px
  }

  &--green {
    background: $btn-green-bg;
  }

  &--transparent {
    background: transparent;
  }

  &--blue {
    color: #fff;
    background: $blue;
  }

  &--not_bold {
    font-weight: 400;
  }

  &--no_styles {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background: none;
    border: 0;
    font: inherit;
    color: inherit;
  }

  &:disabled {
    background: $disabled-btn-bg;
    color: $disabled-btn-color;
    cursor: default;

    &:hover {
      opacity: 1;
    }
  }

  @media screen and (max-width: $menu) {
    padding: 0.9em;
    font-size: 1rem;
  }
}

.page_container {
  max-width: 1440px;
  margin: 0 auto;
  overflow-x: hidden;
  width: 100%;
  padding: 0 24px;
  min-height: 100vh;

  @media screen and (max-width: $mobile) {
    padding: 0 10px;
  }
}

.container {
  padding: 70px 80px;

  @media screen and (max-width: $tablet) {
    padding: 50px 0;
  }

  @media screen and (max-width: $mobile) {
    padding: 20px 0;
  }
}

.grid {
  display: grid;

  &--two_columns {
    grid-template-columns: repeat(2, 1fr);
  }

  &--three_columns {
    grid-template-columns: repeat(3, 1fr);
  }

  &--four_columns {
    grid-template-columns: repeat(4, 1fr);
  }
}

.flex {
  display: flex;

  &--space_between {
    justify-content: space-between;
  }

  &--center_space_between {
    align-items: center;
    justify-content: space-between;
  }

  &--align-items-center {
    align-items: center;
  }

  &--align-items-end {
    align-items: end;
  }

  &--center {
    // place-items: center;
    align-items: center;
    justify-content: center;
  }

  &--column {
    flex-direction: column;
  }

  &--wrap {
    flex-wrap: wrap;
  }
}

.inline-flex {
  display: inline-flex;
}

.title {
  font-size: 3rem; //48px
  font-weight: 600;
  color: $bold-text-color;
  letter-spacing: -0.022em;

  &--big {
    font-size: 3.75rem; //60px
  }

  @media screen and (max-width: $mobile) {
    font-size: 1.5rem;

    &--big {
      font-size: 2rem;
    }
  }
}

.subtitle {
  margin-bottom: 15px;

  font-size: 1.5rem; //24px
  font-weight: 600;
  color: $bold-text-color;
  letter-spacing: -0.019em;
}

.text {
  font-size: 1rem; //16px
  letter-spacing: 0.006em;

  &--big {
    font-size: 1.25rem; //20px
  }

  &--small {
    font-size: 0.8rem; //13px
  }

  &--bold {
    font-weight: 500;
    letter-spacing: -0.017em;
    color: $bold-text-color;
  }

  &--light_gray {
    color: $text-color-light;
  }

  &--dark {
    color: $bold-text-color;
  }

  &--mt {
    margin-top: 10px;
  }

  &--center {
    text-align: center;
  }

  &--success {
    background-color: $success;
    background-image: $success-gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &--warning {
    background-color: $warning;
    background-image: $warning-gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &--error {
    background-color: $error;
    background-image: $error-gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &--word-wrap {
    word-break: break-all;
  }
}

.card {
  border-radius: $card-radius;
  background-color: $card-bg;
  padding: 1.5rem 0.93rem; //24px 15px

  &--big {
    border-radius: $big-card-radius;
  }

  &__inner_block {
    margin-top: 12px;
    padding: 0.5rem 0.93rem; //24px 15px
    border-radius: $card-inner-radius;
    background-color: $card-inner-bg;
  }
}

.full_width {
  width: 100%;
}

.table {
  width: 100%;
  font-size: 1rem;

  &__row {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
    padding: 8px 0;

    border-bottom: 1px solid $line-color;
  }
}

.example_block {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;

  border: 1px solid $line-color;
  border-radius: $br-8;
}

.code {
  font-weight: 400;
  color: rgba(16, 17, 18, 0.7);
  font-size: 0.875rem; //14px
  word-break: break-all;

  &__two_row,
  &__three_row,
  &__four_row {
    margin-left: 20px;
  }

  &__three_row,
  &__four_row {
    display: block;
  }
}

.header_with_line {
  padding-bottom: 20px;
  border-bottom: 1px solid $line-color;

  @media screen and (max-width: $mobile) {
    max-width: 90%;
    padding-bottom: 10px;
  }
}

.lock_icon {
  display: inline-block;
  width: 11px;
  height: 14px;
  margin-right: 4px;
}

.hidden {
  opacity: 0;
}

input.hidden {
  width: 20%;
  position: absolute;
  left: 0;
  z-index: -1;
}

.btn {
  width: fit-content;
  font-family: $font-family;
  color: inherit;
  cursor: pointer;
  border: 0;
  background: 0;
  border-radius: $btn-radius;
  font-size: 1.125rem; //18px
  font-weight: 600;
  padding: 0.625rem 1.25rem; //15px 20px
  background: $btn-bg;

  & svg {
    margin-right: 5px;
  }

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.5;
  }

  &.log_in_btn {
    padding: 15px 24px;

    font-size: 1.375rem; //22px
    color: $light;
    letter-spacing: -0.374px;
    font-weight: bold;

    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);

    @media screen and (max-width: $tablet) {
      font-size: 1.125rem; //18px      
    }
  }

  &--thin {
    font-size: 1.125rem; //18px
    padding: 0.4em 0.6em; //8px 12px
  }

  &--green {
    background: $btn-green-bg;
  }

  &--transparent {
    background: transparent;
  }

  &--blue {
    color: #fff;
    background: $blue;
  }

  &--not_bold {
    font-weight: 400;
  }

  &--no_styles {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background: none;
    border: 0;
    font: inherit;
    color: inherit;
  }

  &:disabled {
    background: $disabled-btn-bg;
    color: $disabled-btn-color;
    cursor: default;

    &:hover {
      opacity: 1;
    }
  }

  @media screen and (max-width: $menu) {
    padding: 0.9em;
    font-size: 1rem;
  }
}