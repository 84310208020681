@import '../../variables';

.indicator {
    &__alert {
        margin-right: 8px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: $icon-bg;

        & p {
            width: 14px;
            height: 14px;
            border-radius: 50%;
        }

        &.success {
            & p {
                background: $success-gradient;
            }
        }

        &.warning {
            & p {
                background: $warning-gradient;
            }
        }

        &.error {
            & p {
                background: $error-gradient;
            }
        }

        &.small {
            margin-right: 4px;
            background: none;
            width: 8px;
            height: 8px;

            & p {
                width: 8px;
                height: 8px;
            }
        }
    }
}