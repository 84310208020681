.message {
    max-width: 700px;
    margin-top: 16px;

    &__icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }

    &__text {
        word-break: break-word;
    }
}