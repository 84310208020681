@import '../../variables';
@import '../../animations';

.loader_container {
    margin-right: 5px;
    width: fit-content;
    height: 100%;

    & span {
        display: inline-block;
    }

    & .loader {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border: none;
        border-radius: 50%;

        border-right: 2px solid $purple-light;
        border-bottom: 2px solid $purple-light;
        animation-name: rotate;
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;

        & .loader_inner {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            border-right: 2px solid $purple-light;
            border-bottom: 2px solid $purple-light;
            animation-name: rotate;
            animation-duration: 2s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
        }
    }

    &.big {
        margin-right: 0;
        margin-top: 15px;
        width: 100%;

        & .loader {
            width: 100px;
            height: 100px;

            & .loader_inner {
                width: 60px;
                height: 60px;
            }
        }
    }
}