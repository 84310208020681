@import "../../../variables.scss";

.how_it_works_card {
    padding: 20px;
    background: $log-in-card-back;
    border-radius: 20px;
    background-repeat: no-repeat;
    background-position: 100% 10px;

    & .number {
        color: $ginger;
        font-size: 2.25rem; //36px
        font-weight: 700;
    }

    &.n1 {
        background-image: url("../../../commons/img/log_in/how_it_works/1.svg")
    }

    &.n2 {
        background-image: url("../../../commons/img/log_in/how_it_works/2.svg")
    }

    &.n3 {
        background-image: url("../../../commons/img/log_in/how_it_works/3.svg")
    }

    &.n4 {
        background-image: url("../../../commons/img/log_in/how_it_works/4.svg")
    }
}