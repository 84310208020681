@import '../../variables.scss';

.add_btn {
    width: 100% !important;
    height: 100%;
    padding: 40px 45px !important;
    // border-radius: 12px;
    border: 1px solid #DFDFDF !important;
    background: $bg-color !important;

    .plus {
        width: 38px;
        height: 38px;
        fill: $text-color-light;
    }

    & .add_btn__text {
        color: $text-color-light;
    }

    &--with_text {
        // border-radius: 20px;

        .plus {
            width: 28px;
            height: 28px;
            margin-right: 15px;
        }
    }


    @media screen and (max-width: 680px) {
        padding: 20px;
    }

    @media screen and (max-width: $mobile) {
        & .add_btn__text {
            font-size: 1.125rem; //18px
        }

        &--with_text {
            .plus {
                width: 20px;
                height: 20px;
            }
        }
    }
}