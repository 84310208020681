@import '../../variables.scss';

.event_card {
    text-align: left;
    width: 100% !important;
    display: block;
    padding: 16px;
    line-height: 1.4;
    color: $text-color-with-opacity;
    background: $card-success-bg;

    &:not(:first-child) {
        margin-top: 16px;
    }

    &.error {
        background: $card-error-bg;
    }

    &.no_webhook {
        background: $card-light-gray-bg;
    }

    &__date {
        margin-bottom: 5px;
    }

    &__address {
        grid-template-columns: repeat(5, 1fr);
        grid-column-gap: 10px;
    }

    &__title {
        margin-bottom: 8px;
        font-size: 1.125rem;
        font-weight: 600;
    }

    &__block {
        &:first-child {
            margin-right: 30px;
        }

        &:last-child {
            text-align: right;
            align-content: end;
        }
    }

    @media screen and (max-width: $mobile) {
        &__inner {
            flex-direction: column;
        }

        &__block {
            width: 100%;

            &:first-child {
                margin-right: 0;
            }

            &:last-child {
                text-align: left;
                align-content: start;
            }
        }
    }
}