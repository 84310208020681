@import '../../variables';

.site_card {
    padding: 20px 18px;

    &.success {
        background-color: $card-success-bg;
    }

    &.warning {
        background-color: $card-warning-bg;

        & .indicator__text {
            background-color:  $warning;
            background-image: $warning-gradient;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    &.error {
        background-color: $card-error-bg;

        & .indicator__text {
            background-color:  $error;
            background-image: $error-gradient;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    &__title {
        padding-bottom: 12px;
        border-bottom: 1px solid $line-color;

        @media screen and (max-width: $mobile) {
            font-size: 2rem;
        }
    }

    &__info {
        margin-top: 5px;
        & .info_row {
            margin-top: 8px;
        }
    }

    & .info_row {
        &__icon_container {
            margin-right: 8px;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: $icon-bg;
    
            & .info_row__icon {
                display: block;
                width: 12px;
                height: 14px;
            }
        }
    }
}
