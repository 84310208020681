@import '../../variables';

.event_detail {

    & .title {
        margin-bottom: 12px;
    }

    &__main {
        margin-top: 40px;

        @media screen and (max-width: $mobile) {
            margin-top: 20px;
        }
    }

    &__section {
        max-width: 800px;
        width: 100%;

        &:not(:first-child) {
            margin-top: 35px;

            @media screen and (max-width: $mobile) {
                margin-top: 30px;
            }
        }
    }

    &__telegram {
        & .no_send {
            width: fit-content;
            padding: 3px 8px;

            color: $text-color-with-opacity;
            font-size: 0.875rem; //14px
            font-weight: 600;
            background: $disabled-btn-bg;
            border-radius: 8px;
        }
    }

    &__webhook_info {
        & p {
            margin-top: 8px;
        }
    }

    & .table {
        min-height: 340px;
        margin-top: 45px;

        &__header {
            font-size: 0.875rem; //14px

            @media screen and (max-width: $mobile) {
                display: none !important;
            }
        }

        & .code {
            display: block;
            margin-top: 10px;
        }

        &__row {
            border: 0;
            grid-template-columns: repeat(12, 1fr);

            & .MuiAccordionSummary-root {
                min-height: 10px;
                height: fit-content;
            }

            & .MuiAccordionSummary-content {
                margin: 0 !important;
            }

            & .MuiAccordionSummary-expandIconWrapper {
                display: block;
                transform: rotate(90deg);
                transition: all 0.5s;
                margin-left: 4px;
            }

            & .Mui-expanded .MuiAccordionSummary-expandIconWrapper {
                transform: rotate(0deg);
            }

            &:not(:first-child) {
                padding-top: 18px;
                padding-bottom: 18px;
            }

            & .first_column {
                grid-column-start: 1;
                grid-column-end: 6;
            }

            & .two_column {
                grid-column-start: 6;
                grid-column-end: 10;
            }

            & .three_column {
                grid-column-start: 10;
                grid-column-end: 12;
            }

            @media screen and (max-width: 820px) {
                grid-gap: 10px;
            }

            @media screen and (max-width: $mobile) {
                grid-template-columns: repeat(1, 1fr);

                & .first_column {
                    grid-column-start: 1;
                    grid-column-end: 1;
                }

                & .two_column {
                    grid-column-start: 1;
                    grid-column-end: 1;
                }

                & .three_column {
                    grid-column-start: 1;
                    grid-column-end: 1;
                }
            }
        }

        &__column {
            & .mobile_title {
                display: none;
            }

            @media screen and (max-width: $mobile) {
                display: flex;

                & .mobile_title {
                    display: block;
                    min-width: 100px;
                    margin-right: 10px;
                }
            }

            @media screen and (max-width: 420px) {
                & .mobile_title {
                    min-width: 80px;
                    font-size: 11px;
                }
            }
        }

        & .answer {
            padding-left: 12px;
            position: relative;

            &::before {
                content: '';
                display: block;
                width: 8px;
                height: 8px;

                position: absolute;
                top: 30%;
                left: 0;
                background: $error-gradient;
                border-radius: 50%;
            }

            &.success {
                &::before {
                    background: $success-gradient;
                }
            }
        }

        & .MuiAccordion-root {
            background: 0 !important;
            box-shadow: none;
            border-radius: 0 !important;
            border-bottom: 1px solid $line-color !important;

            &::before {
                display: none;
            }
        }

        & .MuiAccordionSummary-root {
            width: fit-content;
            padding: 0 !important;
        }

        @media screen and (max-width: $mobile) {
            margin-top: 20px;
        }
    }
}

.status {
    width: fit-content;
    padding: 3px 8px;
    border-radius: 8px;
    font-weight: 600;
    color: #fff;

    &.no_send {
        color: $text-color-with-opacity;
        background: $disabled-btn-bg;
    }

    &.success {
        background: $success-gradient;
    }

    &.error {
        background: $error-gradient;
    }
}