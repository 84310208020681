@import '../../variables';

.pagination {
    margin-top: 20px;

    &__pages_info {
        margin-top: 12px;
    }

    & .MuiPaginationItem-root {
        min-width:28px !important;
        height: 28px !important; 
        font-size: 1rem !important;
        color: $text-color-with-opacity;
    }

    & .Mui-selected {  
        background-color: $dark-gray !important;
        border-radius: 8px !important;
        color: #fff !important; 
    }
    
    @media screen and (max-width: $mobile) {
        margin-top: 10px;
    } 
}

