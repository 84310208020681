@import '../../variables.scss';

.project {
    & .tabs {
        margin-top: 55px;
        margin-bottom: 30px;

        &__btns {
            & .btn {
                &:first-child {
                    margin-right: 8px;
                }

                &.active {
                    background: $dark-gray;
                    color: #fff;
                }
            }
        }
    }

    & .tabs__btn {
        font-size: 1.125rem; //18px
    }

    & .tabs__item {
        transition: all 0.5s;
    }

    &__card {
        &__icon_container {
            width: 24px;
            height: 24px;
            padding: 2px;

            border-radius: 50%;
        }

        &__card__icon {
            display: block;
            width: 11px;
            height: 15px;
        }
    }
}