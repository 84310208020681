@import '../../variables.scss';
@import '../../animations.scss';

.project_card__container {
    position: relative;

    & .edit_project {
        top: 12px;
        right: 12px;
        position: absolute;
        z-index: 5;

        & .open_edit {
            padding: 0;
            width: 60px;
            height: 60px;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 10;

            border-radius: $btn-radius;
            border: 1px solid $light;
            background: $card-bg;
            transition: all 0.2s ease-out;

            &:hover {
                opacity: 1;
                background: $bg-color;
            }

            & .dots {
                opacity: 1;
            }

            & .close {
                opacity: 0;
            }

            & svg {
                display: block;
                width: 30px;
                height: auto;
                position: absolute;
                top: 27px;
                left: 15px;
            }

            &.opened {
                width: 60px;
                height: 60px;
                background: #e6d1cd;
                border: 0;
                top: 0;
                right: 0;

                & span {
                    display: block;
                    width: 50px;
                    height: 50px;
                    border-radius: $br-8;
                    background: #d1beba;
                    position: relative;
                }

                & .dots {
                    opacity: 0 !important;
                }

                & .close {
                    width: 14px;
                    opacity: 1 !important;
                    top: 18px;
                    left: 18px;
                }
            }
        }

        & .edit_project__btn {
            // width: 300px;
            padding: 20px;
            font-size: 1rem;
            font-weight: 500;
            border-radius: $btn-radius;
            background: rgba($dark-gray, 0.1);
            width: 60px;
            height: 60px;
            overflow: hidden;
            transition: all 0.2s ease-out;
            display: flex;

            & span {
                display: block;
                margin-left: 8px;
                height: 20px;
            }

            &.open {
                opacity: 1;
                width: 300px !important;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        & .edit_project {
            & .open_edit {
                width: 40px;
                height: 40px;

                & svg {
                    width: 20px;
                    top: 18px;
                    left: 10px;
                }

                &.opened {
                    width: 40px;
                    height: 40px;

                    & span {
                        width: 30px;
                        height: 30px;
                    }

                    & .close {
                        width: 8px;
                        top: 10.5px;
                        left: 10.5px;
                    }
                }
            }

            & .edit_project__btn {
                // width: 300px;
                padding: 15px 10px;
                width: 40px;
                height: 40px;
                font-size: 0.8rem;

                & .edit {
                    width: 15px;
                    height: 15px;
                }

                & span {
                    margin-left: 3px;
                }

                &.open {
                    width: 220px !important;
                }
            }
        }
    }
}

.project_card {
    padding: 40px;
    margin-top: 40px;
    background-color: $card-bg;
    color: inherit;
    flex-direction: column;
    z-index: 0;

    &__part {
        height: 100%;
    }

    & .card__title {
        margin-top: 100px;
        font-weight: 600;
        font-size: 7.375rem; //118px
    }

    & .card__info {
        flex-direction: column;
        margin-bottom: 40px;
        font-size: 1.25rem;
        color: $text-color-light;

        & p {
            margin-top: 10px;
        }
    }

    @media screen and (max-width: $tablet) {
        & .card__title {
            margin-top: 50px;
            font-size: 5rem; //118px
        }
    }

    @media screen and (max-width: 680px) {
        padding: 20px;
    }

    @media screen and (max-width: $mobile) {
        margin-bottom: 20px;

        & .card__title {
            font-size: 2.3rem;
        }

        & .card__info {
            margin-bottom: 20px;
            font-size: 1rem;
        }

    }
}