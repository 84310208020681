@import '../../variables.scss';

.subpage_main {
    margin-top: 60px;

    @media screen and (max-width: $tablet) {
        margin-top: 40px;
        grid-template-columns: repeat(1, 1fr) !important;

        & .right_block {
            margin-top: 30px;
        }
    }

    @media screen and (max-width: $mobile) {
        margin-top: 30px;

        & .right_block {
            margin-top: 20px;
        }
    }
}