@import '../../../variables.scss';

.project_list {
    max-width: 640px;
    width: 100%;
    margin-top: 20px !important;

    grid-template-columns: 1fr;
    grid-gap: 12px;

    &__item {
        padding: 20px;

        font-size: 1.125rem; //18px

        overflow: hidden;
        background-color: $card-bg;
        border-radius: $card-radius;

        &.blue {
            background-color: $light-blue;
        }

        &.green {
            background-color: $light-green-extra;
        }

        &.purple {
            background-color: $purple-light;
        }

        & .addresses {
            font-size: 1rem;
        }
    }

    &__text {
        max-width: 80%;
        word-wrap: break-word;
        margin-right: 10px;
        font-weight: 600;
    }

    &__icon_container {
        margin-right: 8px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: $icon-bg;

        & .book_icon {
            display: block;
            width: 12px;
            height: 14px;
        }
    }


    & .add_btn {
        padding: 20px !important;

        & .title {
            font-size: 1.125rem !important; //18px
        }

        & .plus {
            width: 17px;
            height: 17px;
        }
    }

    @media screen and (max-width: $mobile) {
        &__item {
            font-size: 1rem;
        }

        & .addresses {
            font-size: 0.8rem;
        }
    }
}