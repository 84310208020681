@import '../../variables.scss';

.website {
    & .subpage_main {
        grid-gap: 70px;
    }

    & section,
    & .right_block {
        max-width: 530px;
        width: 100%;
    }

    & .delete_btn {
        margin-top: 12px;
    }

    &__status {
        line-height: 1.5;
    }

    &__info {
        margin-top: 25px;

        .example_block {
            margin-bottom: 12px;
        }

        & p:not(.text--error) {
            margin-bottom: 12px;
        }

        & .download {
            margin-top: 12px;
        }
    }

    & .check_now_btn {
        margin-bottom: 24px;
    }

    & .indicator__text {
        background-color: $success;
        background-image: $success-gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    & .table__row {
        padding-left: 10px;
        padding-right: 10px;

        &:first-child {
            font-size: 14px;
        }

        &:not(:first-child) {
            & .table__column {
                padding-top: 18px;
                padding-bottom: 18px;
            }
        }
    }

    @media screen and (max-width: $tablet) {
        & .subpage_main {
            grid-gap: 0px;
        }
    }
}