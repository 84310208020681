@import '../../variables';

.footer {
    &__links {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        margin-top: 25px;

        @media screen and (max-width: 900px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (max-width: $mobile) {
            grid-template-columns: 1fr;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 20px 20px 24px;
        border-radius: 20px;

        & span {
            text-decoration: underline;
            font-size: 24px;
            font-weight: bold;

            @media screen and (max-width: $tablet) {
                font-size: 18px;
            }
        }

        & img {
            width: 60px;
            height: auto;

            @media screen and (max-width: $tablet) {
                width: 40px;
            }

            @media screen and (max-width: $mobile) {
                width: 50px;
            }
        }

        &.telegram {
            background: $blue;
            color: #fff;
        }

        &.blog {
            background: #FBECEF;
        }

        &.base {
            background: #96AFF9;
        }

        &.github {
            background: #25292E;
            color: #fff;
        }
    }
}

.footer__language-box {
    margin-top: 92px;
    display: flex;
    justify-content: flex-end;
}