@import '../../variables';

.rool {
    &__row {
        margin-bottom: 10px;
    }

    & .left_part {
        max-width: 60%;
    }

    & .section {
        &:not(:first-child) {
            margin-top: 40px;
        }
    }

    & .edited_count {
        position: relative;
        cursor: pointer;

        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background: $text-color-with-opacity;

            position: absolute;
            bottom: -3px;
            left: 0;
            right: 0;
        }
    }

    @media screen and (max-width: $tablet) {
        & .left_part {
            max-width: 100%;
        }

        & .section {
            &:not(:first-child) {
                margin-top: 25px;
            }
        }
    }
}