@import "../../../variables.scss";

.advantages_card {
    padding: 20px 20px 50px;
    line-height: 1.4;
    background: $log-in-card-back;
    border-radius: 20px;

    &__title {
        color: $ginger;
        font-size: 1.75rem; //28px
        font-weight: 700;
        line-height: 1.4;
        letter-spacing: 0.168px;
    }

    &__descr {
        color: #000;
        margin-top: 14px;
        font-size: 1.25rem; //20px      
        letter-spacing: 0.12px;
    }
}