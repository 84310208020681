@import '../../../variables';

.li_item__form {
    & .subtitle {
        margin-top: 36px;
    }

    & .card__inner_block {
        min-height: 515px;

        &.error {
            border: 1px solid $error;
        }
    }

    & .form_btns {
        margin-top: 16px;
    }

    & textarea {
        display: block;
        width: 100%;
        min-height: 515px;
        background: 0;
        border: 0;
        outline: none;
    }

    @media screen and (max-width: $mobile) {
        & .card__inner_block {
            min-height: 300px;
        }
    }
}

.rools__form__title {
    min-height: max-content;
}