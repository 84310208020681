@import '../../variables';

.sites {
    &__cards {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 24px;

        @media screen and (max-width: $tablet) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (max-width: $mobile) {
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 16px;
        }
    }

    &__form {
        min-width: 768px;

        & .form_btns {
            margin-top: 36px;
        }

        @media screen and (max-width: 870px) {
            min-width: 100%;

            & .form_btns {
                margin-top: 20px;
            }
        }
    }

}