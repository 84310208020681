@import '../../variables';

.balance_page {
    &__btns {
        margin-top: 24px;

        & .btn:first-child {
            margin-right: 16px;
        }
    }

    & .table {
        max-width: 760px;
        width: 100%;
        margin-top: 45px;

        &__header {
            font-size: 0.875rem; //14px

            @media screen and (max-width: $mobile) {
                display: none !important;
            }
        }

        & .cause {
            position: relative;

            &::before {
                content: '';
                display: block;
                width: 8px;
                height: 8px;

                position: absolute;
                top: 30%;
                left: 0;

                border-radius: 50%;
            }

            &.process {
                padding-left: 12px;

                &::before {
                    background: rgba(16, 17, 18, 0.2);
                }
            }

            &.success {
                padding-left: 12px;

                &::before {
                    background: $success-gradient;
                }
            }

            &.error {
                padding-left: 12px;

                &::before {
                    background: $error-gradient;
                }
            }
        }

        & .date_btn {
            position: relative;
            color: inherit;

            &::after {
                content: '↑';
                display: block;
                width: 8px;
                height: 11px;
                position: absolute;
                top: 0;
                right: -5px;
                transition: all 0.2s;
            }

            &.to_down {
                &::after {
                    top: 7px;
                    transform: rotate(180deg);
                }
            }
        }

        &__row {
            grid-template-columns: repeat(12, 1fr);

            &:not(:first-child) {
                padding-top: 18px;
                padding-bottom: 18px;
            }

            & .first_column {
                grid-column-start: 1;
                grid-column-end: 5;
            }

            & .two_column {
                grid-column-start: 5;
                grid-column-end: 11;
            }

            & .three_column {
                grid-column-start: 11;
                grid-column-end: 12;
            }

            @media screen and (max-width: $mobile) {
                grid-template-columns: repeat(1, 1fr);

                & .first_column {
                    grid-column-start: 1;
                    grid-column-end: 1;
                }

                & .two_column {
                    grid-column-start: 1;
                    grid-column-end: 1;
                }

                & .three_column {
                    grid-column-start: 1;
                    grid-column-end: 1;
                }
            }
        }

        &__column {
            & .mobile_title {
                display: none;
            }

            @media screen and (max-width: $mobile) {
                display: flex;

                & .mobile_title {
                    display: block;
                    min-width: 60px;
                    margin-right: 10px;
                }
            }
        }

        @media screen and (max-width: $mobile) {
            margin-top: 20px;
        }
    }

    & form {
        min-width: 768px;

        & .form_btns {
            margin-top: 36px;
        }

        @media screen and (max-width: 870px) {
            min-width: 100%;

            & .form_btns {
                margin-top: 20px;
            }
        }
    }
}