@import "../../variables.scss";

.f-hamburger-switch {
    margin-right: 8px;
    height: 100%;
    padding: 10px 8px 8px 10px !important;
    cursor: pointer;

    .cont {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 4px;
        position: relative;
        width: 30px;
    }

    .bx {
        transition: all 0.5s;
        width: 100%;
        height: 4px;
        background: black;
        border-radius: 30%;
    }
}