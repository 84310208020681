.rools {
    margin-top: 20px;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 24px;

    & .add_btn {
        min-height: 320px;
    }

    @media screen and (max-width: 1420px) {
        grid-gap: 15px;
        grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (max-width: 960px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 700px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 450px) {
        grid-template-columns: repeat(1, 1fr);

        & .add_btn {
            min-height: 90px !important;
        }
    }
}