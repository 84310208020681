@import '../../variables';

.li_page {
    &__addresses {
        min-height: 265px;
        margin-top: 14px;

        & li {
            margin-top: 8px;
        }
    }

    &__address {
        margin-right: 5px;
    }

    &__rool {
        display: block;
        font-weight: 600;

        padding: 16px;
        margin-top: 8px;
        background-color: $card-bg;
        border-radius: $card-radius;

        &.blue {
            background-color: $light-blue;
        }

        &.green {
            background-color: $light-green-extra;
        }

        &.purple {
            background-color: $purple-light;
        }

    }

    &__instruction {
        margin-top: 48px;

        & .example_block {
            margin-top: 16px;
        }
    }

    @media screen and (max-width: $tablet) {
        &__addresses {
            min-height: fit-content;
        }
    }

    @media screen and (max-width: $mobile) {
        &__instruction {
            margin-top: 20px;

        }
    }
}