@import '../../variables';

.events {
    & .small-select {
        min-width: 200px;
        margin-right: 19px;
    }

    & .big-select {
        min-width: 420px;
    }

    & .event_card {
        text-align: left;
        font-weight: 500;
        font-size: 1rem;

        & .event_card__block:first-child {
            color: $bold-text-color;
            word-wrap: break-word;
        }
    }

    @media screen and (max-width: 910px) {
        &__title {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 20px;
        }
    }

    @media screen and (max-width: 685px) {
        &__inputs {
            flex-direction: column;
            align-items: flex-start;
        }

        & .big-select {
            margin-top: 10px;
            min-width: 300px;
        }
    }
}