@import '../../../variables';

.edit_history {
    &__main {
        @media screen and (max-width: 910px) {
            flex-direction: column;
        }
    }

    &__statistic {
        padding: 36px 36px 36px 0;
        border-right: 1px solid $line-color;

        & .statistic__card {
            padding: 16px;
            margin-bottom: 16px;
            border-radius: $card-radius;
            background: rgba(16, 17, 18, 0.05);

            &:first-child {
                box-shadow: 0px 0px 1px rgba(26, 32, 36, 0.16), 0px 4px 12px rgba(91, 104, 113, 0.12);
                background: #FFFFFF;
            }

            & p:first-child {
                margin-bottom: 4px;
            }
        }

        @media screen and (max-width: 910px) {
            display: flex;
            flex-wrap: wrap;
            padding: 10px 0 20px;
            border-right: 0;
            border-bottom: 1px solid $line-color;

            & .statistic__card {
                margin-top: 10px;
                margin-bottom: 0;

                &:not(:last-child) {
                    margin-right: 16px;
                }
            }
        }

        @media screen and (max-width: $mobile) {
            & .statistic__card {
                margin-right: 0 !important;
                width: 100%;
            }
        }
    }

    &__info {
        flex-grow: 1;
        padding: 36px 0 100px 36px;

        @media screen and (max-width: 910px) {
            padding: 20px 0;
        }
    }

    & .table {
        margin-top: 50px;

        @media screen and (max-width: 910px) {
            margin-top: 30px;
        }
    }
}