@import "../../variables";

.title_input {
    // width: 100%;
    max-width: 80%;
    // max-height: 85px;
    padding-bottom: 10px;
    background: none;
    border: 0;
    font-size: 3.75rem; //60px
    position: relative;
    // overflow: hidden;

    & .hide {
        opacity: 0;
    }

    &__text {
        position: absolute;
        pointer-events: none;

        & span {
            display: block;
            width: 500%;
        }

        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 5px;
            border-bottom: 3px dashed $input-title-border-color;
            position: absolute;

            top: 65px;
            // bottom: -5px;
        }

        &.error {
            &::before {
                border-bottom: 3px dashed $error !important;
            }
        }

        & .value {
            color: $bold-text-color;
        }

        & .placeholder {
            color: $text-color-light;
        }
    }

    & input {
        display: block;
        width: 100%;
        background: $modal-bg-color;
        border: 0;
        font-size: 1em;

        outline: none;
    }

    @media screen and (max-width: $tablet) {
        font-size: 2.5rem;
        max-height: 60px;

        &__text {
            &::before {
                top: 45px;
            }
        }
    }

    @media screen and (max-width: $menu) {
        max-width: 70%;
    }

    @media screen and (max-width: $mobile) {
        margin-top: 30px;
        font-size: 2rem;
        max-height: 50px;
        max-width: 95%;

        &__text {
            &::before {
                top: 40px;
            }
        }
    }
}