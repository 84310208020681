@import '../../variables.scss';

.rools_card {
    min-height: 320px;
    display: block;
    padding: 20px;
    overflow: hidden;

    &.blue {
        background-color: $card-blue-bg;
    }

    &.green {
        background-color: $card-success-bg;
    }

    &.purple {
        background-color: $card-purple-bg;
    }

    &__inner {
        height: 100%;
        width: 100%;
        margin: 0 auto;
    }

    &__header {
        max-width: 208px;
        flex-grow: 1;
        border-bottom: 1px solid $input-title-border-color;

        @media screen and (max-width: $mobile) {
            max-width: 100%;
        }
    }

    &__title-box {
        display: flex;
        flex-direction: row; 
    }

    &__title {
        margin-top: 20px;
        min-height: 30px;

        @media screen and (max-width: $mobile) {
            margin-top: 15px;
            margin-bottom: 10px;
            min-height: 13px;
        }
    }

    &__span {
        color: rgb(158, 169, 169);
    }

    &__title_grey {
        margin-top: 20px;
        min-height: 30px;
        color: rgb(158, 169, 169);
        margin-left: 10px;

        @media screen and (max-width: $mobile) {
            margin-top: 15px;
            margin-bottom: 10px;
            min-height: 13px;
        }   
    }

    &__address {
        & .zero {
            color: $text-color-with-opacity-extra;
        }

        & .rest {
            word-wrap: break-word;
            max-height: 150px;
            overflow: hidden;
            grid-column-gap: 15px;
            color: $text-color-with-opacity;
            font-weight: 400;

            &--ml {
                margin-left: 15px;
            }

            & p {
                text-align: justify;
            }

            @media screen and (max-width: 1360px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media screen and (max-width: 930px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media screen and (max-width: 700px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media screen and (max-width: 360px) {
                grid-template-columns: repeat(2, 1fr);
            }
        }

        @media screen and (max-width: $mobile) {
            font-size: 1.1rem;
        }
    }

    & .indicator {
        margin-top: 15px;
        margin-bottom: 10px;
    }

    &__icon_container {
        margin-right: 8px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: $icon-bg;
    }

    &__icon {
        display: block;
        width: 18px;
        height: 18px;
    }

    &__bottom_info {
        height: 70px;
    }

    @media screen and (max-width: $tablet) {
        &__title {
            font-size: 1.5rem;
        }
    }

    @media screen and (max-width: $mobile) {
        min-height: 270px;

        &__title {
            font-size: 1.125rem; //18px
        }
    }
}

.disActiveData {
    display: inline-block;
    width: 44px;
    height: 24px;
    border-radius: 19px;
    background: rgba(16, 17, 18, 0.1);
    z-index: 0;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    position: relative;
    transition-duration: 0.4s;
}

.disActiveData::after {
    content: '';
    height: 20px;
    width: 20px;
    border-radius: 17px;
    background: linear-gradient(215.82deg, #FF655B 15.71%, #FF49A1 85.08%);
    top: 2px;
    left: 2px;
    transition-duration: 0.4s;
    position: absolute;
    z-index: 1;
}

.activeData {
display: inline-block;
width: 44px;
height: 24px;
border-radius: 20px;
background: linear-gradient(215.82deg, #8ABE1C 15.71%, #46AD07 85.08%);
z-index: 0;
margin: 0;
padding: 0;
border: none;
cursor: pointer;
position: relative;
transition-duration: 0.4s;
}

.activeData::after {
content: '';
height: 20px;
width: 20px;
border-radius: 20px;
background: rgba(255, 255, 255, 1);
top: 2px;
left: 22px;
transition-duration: 0.4s;
position: absolute;
z-index: 1;
}

.tumbler__text {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.017em;
    text-align: left;

}

.tumbler__box {
    display: flex;
    justify-content: space-between;
    margin: 12px 0 8px 0;
}