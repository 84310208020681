@import "../../variables.scss";

.faq {
    border-top: 1px solid #CFCFD0;
    border-bottom: 1px solid #CFCFD0;

    & .MuiAccordion-root {
        background-color: $bg-color;
        box-shadow: none;

        &::before {
            height: 1px;
            background-color: #CFCFD0;
            opacity: 1;
        }
    }

    & .MuiAccordionSummary-root {
        color: $bold-text-color;
        font-size: 1.5rem; // 24px
        font-weight: 600;

        @media screen and (max-width: $mobile) {
            font-size: 1.125rem; //18px
        }
    }

    & .MuiAccordionSummary-content {
        margin: 20px 0;
        margin-right: 20px;
    }

    & .MuiAccordionDetails-root {
        color: $log-in-links-color;
        font-family: $font-family;
        font-size: 1.125rem; //18px
        font-weight: 400;
        line-height: 1.4;

        @media screen and (max-width: $mobile) {
            font-size: 1rem;
        }
    }
}