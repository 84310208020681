@import '../../variables.scss';

.projects {
    &__form {
        display: block;
        max-width: 760px;
        width: 100%;
        margin-top: 10px;

        & .form_btns {
            margin-top: 40px;
        }

        @media screen and (max-width: $mobile) {
            & .form_btns {
                margin-top: 20px;
            }
        }
    }

    & .add_btn {
        border-radius: $big-card-radius;
    }
}