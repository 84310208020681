.condition_input {
    &.disabled {
        & .MuiInputBase-root {
            background: none !important;
            border: 1px solid rgba(16, 17, 18, 0.2);
        }

        & .MuiSelect-select {
            background: none !important;
            border: 1px solid rgba(16, 17, 18, 0.2);
        }
    }

    & .rools__form__btn {
        margin-left: 12px;
    }

    & .cond_btn_icon {
        pointer-events: none;
    }
}