@import "../../../variables.scss";

.about_card {
    grid-template-columns: 287px 1fr 1fr;
    grid-gap: 47px;

    @media screen and (max-width: $menu) {
        grid-template-columns: 250px 1fr 1fr;
        grid-gap: 50px;
    }

    @media screen and (max-width: $mobile) {
        grid-template-columns: 1fr;
        grid-gap: 30px;
    }

    &:not(:first-child) {
        margin-top: 60px;
    }

    & .img_container {
        width: 100%;
        height: 287px;
        grid-column-start: 1;
        grid-column-end: 2;

        & .img {
            display: block;
            max-width: 287px;
            height: auto;
        }

        @media screen and (max-width: $menu) {
            height: 250px;
        }
    }

    &__text {

        grid-column-start: 2;
        grid-column-end: 4;
        justify-content: center;

        @media screen and (max-width: $mobile) {
            grid-column-start: 1;
            grid-column-end: 2;
        }
    }

    & .descr {
        margin-top: 20px;
    }
}