@import '../../variables.scss';

.log_in {
    padding-bottom: 265px;
    overflow-x: hidden;
    color: $bold-text-color;
    font-size: 1.125rem; //18px

    position: relative;

    @media screen and (max-width: $menu) {
        font-size: 1rem; //18px
        padding-bottom: 100px;
    }

    &__section {
        max-width: 1080px;
        width: 100%;
        margin: 100px auto 0;
        padding: 0 24px;

        @media screen and (max-width: $menu) {
            margin: 50px auto 0;
        }

        @media screen and (max-width: $mobile) {
            padding: 0 10px;
        }
    }

    &__title {
        font-size: 3.75rem; //60px
        font-weight: bold;
        letter-spacing: -1.32px;
        line-height: 1.2;

        @media screen and (max-width: $menu) {
            font-size: 3rem;
        }

        @media screen and (max-width: $mobile) {
            font-size: 2.25rem; //36px
        }
    }

    &__subtitle {
        font-size: 2.25rem; //36px
        font-weight: bold;
        letter-spacing: -0.792px;
        line-height: 1.2;

        @media screen and (max-width: $menu) {
            font-size: 2rem;
        }

        @media screen and (max-width: $mobile) {
            font-size: 1.5rem;
        }
    }

    & .first_screen {
        & .btn {
            margin-top: 24px;
        }

        & .img {
            width: 520px;
            height: 520px;
            margin-top: -35px;
            margin-right: -176px;

            @media screen and (max-width: $tablet) {
                margin-right: 0;
                width: 390px;
                height: 390px;
            }

            @media screen and (max-width: 999px) {
                display: none;
            }
        }

        &__inner {
            max-width: 640px;
            width: 100%;
        }

        &__descr {
            margin-top: 24px;
        }

        &__list {
            padding-left: 20px;
        }
    }

    & .how-it-works {
        &__cards {
            grid-gap: 20px;
            grid-template-rows: 290px;

            @media screen and (max-width: $menu) {
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, 290px);
            }

            @media screen and (max-width: $mobile) {
                grid-template-columns: 1fr;
                grid-template-rows: repeat(4, 250px);
            }
        }
    }

    & .networks {
        & .log_in__subtitle {
            margin-bottom: 20px;
        }

        &__networks {
            margin-top: 40px;
            gap: 40px;
        }

        &__title {
            max-width: 640px;
            width: 100%;
        }

        &__descr {
            max-width: 580px;
            width: 100%;
        }

        .network {
            max-width: 80px;
            width: 100%;
            text-align: center;

            font-size: 0.8rem; //13px
            line-height: 1.4;

            &:last-child {
                color: rgba(16, 17, 18, 0.50);
            }
        }
    }

    & .advantages {
        &__cards {
            margin-top: 25px;
            grid-gap: 20px;

            @media screen and (max-width: $menu) {
                grid-template-columns: 1fr;
            }
        }
    }

    & .banner {
        margin-top: 160px;
        height: 500px;
        background-color: #30E97A;

        @media screen and (max-width: $mobile) {
            margin-top: 50px;
        }

        &__inner {
            max-width: 1440px;
            width: 100%;
            height: 100%;
            margin: 0 auto;

            background-image: url('../../commons/img/log_in/banner_bg.svg');
            background-repeat: no-repeat;
            background-position: 100% 80%;

            @media screen and (max-width: $tablet) {
                background-size: 80% auto;
            }

            @media screen and (max-width: $menu) {
                background-position: 100% 90%;
            }

            @media screen and (max-width: $mobile) {
                background-image: none;
            }
        }

        &__main {
            max-width: 1080px;
            width: 100%;
            margin: 0 auto;
            padding: 0 24px;

            & .btn {
                padding: 15px 24px;
                margin-top: 60px;
                font-size: 22px;

                font-weight: 700;
                letter-spacing: -0.374px;

                border-radius: 12px;
                background: #fff;
                box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);

                @media screen and (max-width: $mobile) {
                    margin-top: 30px;
                }

            }

            @media screen and (max-width: $mobile) {
                padding: 0 10px;
            }
        }

        &__title {
            max-width: 750px;
            width: 100%;

            font-size: 3rem; //48px
            font-weight: 700;
            letter-spacing: -1.056px;
            line-height: 1.4;

            @media screen and (max-width: $mobile) {
                font-size: 2.5rem;
            }
        }
    }
}