@import "../../variables.scss";

.header {
    padding-top: 16px;
    font-size: 1.125rem; //18px
    background-color: $bg-color;

    & .burger_container {
        display: none;
    }

    & .nav {
        flex-grow: 1;

        & .nav-item {
            padding: 0 12px;
            position: relative;

            &.active {
                &::after {
                    content: "";
                    display: block;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: #ADFF00;

                    position: absolute;
                    bottom: -10px;
                    left: 45%;
                }
            }
        }

        @media screen and (max-width: $menu) {
            flex-grow: unset;
        }
    }

    & .address_container {
        position: relative;

        & .address {
            padding: 0 12px;
            font-size: 1.125rem; //18px

            & .arr {
                width: 9.5px;
                height: 5.5px;

                position: absolute;
                top: 40%;
                right: -10px;

                transition: all 0.5s;

                &.open {
                    transform: rotate(180deg);
                }
            }

            &__address {
                margin-left: 8px;
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 8px;
                color: $text-color-with-opacity;
            }
        }

        & .log_out_btn--mobile {
            display: none;
        }

        & .address__opened_menu {
            padding: 16px;
            width: 224px;
            top: 35px;
            right: 0;
            position: absolute;
            transition: all 0.5s;
            opacity: 0;
            background: #FFFFFF;
            border-radius: $card-radius;
            box-shadow: 0px 0px 1px rgba(26, 32, 36, 0.16), 0px 8px 24px rgba(91, 104, 113, 0.14);
            pointer-events: none;

            & .full_address {
                word-wrap: break-word;
            }

            &.open {
                pointer-events: auto;
                opacity: 1;
                z-index: 20;
            }

            & .btn {
                display: flex;
                align-items: center;
                margin-top: 15px;
                border-radius: 8px;
            }
        }
    }

    @media screen and (max-width: $menu) {
        position: relative;

        & .burger_container {
            display: block;
            width: fit-content;
            margin: 0 0 0 auto;

            &.open {
                .bx:first-child {
                    transform: rotate(45deg);

                }

                .bx:last-child {
                    transform: rotate(-45deg) translateY(-5px) translateX(5px);
                }
            }

            &:hover {
                opacity: 0.6;
            }
        }

        & .header__menu {
            width: 100%;
            height: 100vh;
            transform: translateX(850px);
            transition: all 0.5s;
            position: absolute;
            z-index: 15;
            background-color: $bg-color;
            flex-direction: column;

            & .nav {
                margin-top: 20px;
                flex-direction: column;

                & p {
                    flex-direction: column;
                }

                & .nav-item {
                    margin-bottom: 20px;
                }
            }

            &.open {
                transform: translateX(0);
            }

            & .address_container {
                display: flex;

                & .arr {
                    display: none;
                }

                & .log_out_btn--mobile {
                    display: block;
                    padding: 0;
                    font-size: 1rem;
                    font-weight: 400;
                    font-size: 1.125rem; //18px

                    & svg {
                        height: 0.8rem;
                    }
                }

                & .address__opened_menu {
                    display: none;
                }
            }
        }
    }
}

.adress__button-box {
    display: flex;
    margin-bottom: 10px;
}