@keyframes show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes rotate {
    from {
      transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
} 