@import "../../variables.scss";

@keyframes fadeIn {
    0% {
        opacity: 100%;                    
    }
    50% {
        opacity: 50%;
    }
    100% {
        opacity: 0%;
    }
}

.log_in_header {
    max-width: 1440px;
    margin: 0 auto;
    padding: 16px 24px 0;
    font-size: 1.375rem; //22px

    & .logo {
        width: 83px;
        height: auto;
    }

    & .burger_container {
        display: none;
    }

    & .nav {
        width: fit-content;

        &__item {
            margin-right: 20px;
            color: $log-in-links-color;
        }
    }

    @media screen and (max-width: $tablet) {
        font-size: 1.125rem; //18px      
    }


    @media screen and (max-width: $menu) {
        position: relative;

        & .burger_container {
            display: flex;
            width: 100%;
            margin: 0 0 0 auto;
            justify-content: space-between;
            margin-top: 15px;

            &.open {
                .bx:first-child {
                    transform: rotate(45deg);

                }

                .bx:last-child {
                    transform: rotate(-45deg) translateY(-5px) translateX(5px);
                }

                &:hover {
                    opacity: 0.6;
                }

                & .logo {
                    animation: 0.5s fadeIn;
                    opacity: 0%;
                }
            }
        }

        & .header__menu {
            width: 100%;
            height: 100vh;
            transform: translateX(850px);
            transition: all 0.5s;
            position: absolute;
            z-index: 15;
            background-color: $bg-color;
            flex-direction: column;
            justify-content: start;

            & .logo {
               display: block;
            }

            &.open {
                transform: translateX(0);
            }

            & .nav {
                margin-top: 20px;
                flex-direction: column;

                &__item {
                    margin-right: 0;
                    margin-bottom: 20px;
                }
            }

            & .menu_inner {
                flex-direction: column;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 0 10px;
    }
}