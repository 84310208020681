@import '../../variables';

.form_btns {
    & .btn {
        margin-top: 15px;
        margin-right: 16px;
    }

    & .left_btns {
        flex-wrap: wrap;
    }

    & .waiting_text {
        margin-left: 5px;
    }

    & .delete_icon {
        width: auto;
        height: 20px !important;
    }

    & .delete_btn {
        &:disabled {
            & .delete_icon path {
                fill: rgba(16, 17, 18, 0.2);
            }
        }
    }

    & .test_btn {
        &:disabled {
            background: none;
        }
    }

    @media screen and (max-width: $mobile) {
        flex-direction: column;
        align-items: flex-start;

        & .delete_icon {
            height: 18px !important;
        }
    }

    @media screen and (max-width: 400px) {
        & .left_btns {
            width: 100%;
            flex-direction: column;
        }

        & .test_btn {
            order: 1;
        }

        & .submit_btn {
            order: 2;
        }

        & .cancel_btn {
            order: 3;
        }

        & .btn {
            width: 100%;
            align-items: center;
            justify-content: center;
        }

        & .delete_btn {
            margin-top: 16px;
        }
    }
}