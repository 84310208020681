$font-family: 'Inter', 'Arial', sans-serif;

$bg-color: #FDF8F4;
$line-color: rgba(16, 17, 18, 0.1);

//card
$card-bg: #FEE6E2;
$card-blue-bg: #E2EDFE;
$card-success-bg: #DEF8DA;
$card-warning-bg: #FFE9D7;
$card-error-bg: #FEE6E2;
$card-purple-bg: #F0E2FE;
$card-light-gray-bg: rgba(16, 17, 18, 0.03);
$card-inner-bg: rgba(16, 17, 18, 0.05);
$big-card-radius: 20px;
$card-radius: 12px;
$card-inner-radius: 8px;
$card-shadow: 0px 0px 1px rgba(122, 77, 24, 0.16), 0px 8px 24px rgba(135, 100, 58, 0.14);
$log-in-card-back: #FAEFE5;

// text
$text-color-with-opacity-extra: rgba(16, 17, 18, 0.1);
$text-color-with-opacity: rgba(16, 17, 18, 0.5);
$text-color-light: #666970;
$log-in-links-color: #585959;
$text-color: #242833;
$bold-text-color: #101112;


$success: #8ABE1C;
$error: #FF49A1;
$warning: #F9C717;

//select and input
$select-label-color: rgba(16, 17, 18, 0.5);
$select-bg-color: #f1ece9;
$open-select-bg-color: #fff;
$select-br: 12px;
$selected-bg-color: #e7e7e7;

$input-title-border-color:  rgba(16, 17, 18, 0.1);
$input-radius: 12px;

$icon-bg: rgba(16, 17, 18, 0.05);

//modal
$modal-bg-color: #FDF8F4;
$modal-back-bg-color: #FAEFE5;
$modal-back-bg-opacity: 0.8;
$modal-radius: 20px;
$modal-shadow: 0px 0px 1px rgba(26, 32, 36, 0.16), 0px 8px 24px rgba(91, 104, 113, 0.14);

//btn
$btn-bg: rgba(16, 17, 18, 0.1);
$btn-green-bg: #11CA71;
$btn-green-color-light: #fff;
$disabled-btn-bg: rgba(16, 17, 18, 0.05);
$disabled-btn-color: rgba(16, 17, 18, 0.2);
$btn-radius: 12px;
$btn-thin-radius: 8px;

//gradients
$success-gradient: linear-gradient(215.82deg, #8ABE1C 15.71%, #46AD07 85.08%);
$warning-gradient: linear-gradient(215.82deg, #F9C717 15.71%, #E89C08 85.08%);
$error-gradient: linear-gradient(215.82deg, #FF655B 15.71%, #FF49A1 85.08%);

// palette
$light: #F2F2F2;
$beige: #FDF8F4;
$pink-light: #FEE6E2;
$light-blue: #E2EDFE;
$blue: #3BAAFB;
$light-green-extra: #DEF8DA;
$light-green: #8ABE1C;
$green: #11CA71;
$dark-green: #46AD07;
$yellow: #F9C717;
$orange: #E89C08;
$orange-bright: #FF655B;
$ginger: #F39743;
$purple-light: #F0E2FE;
$pink: #FF49A1;
$bright-red: #BA2323;
$simple-gray-light: #666970;
$light-gray-more-opacity: rgba(16, 17, 18, 0.1);
$light-gray: rgba(16, 17, 18, 0.03);
$light-gray-less-opasity:rgba(16, 17, 18, 0.5);
$gray: #242833;
$dark-gray: #101112;

// adaptive
$tablet: 1200px;
$mobile: 560px;
$menu: 800px;

//radiuses
$br-8: 8px;
$br-12: 12px;
$br-20: 20px;


